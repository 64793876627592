import revive_payload_client_I6BKwuD2zX from "/vercel/path0/src/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.12.7_eslint@8.57.0_sass@1.75.0_stylelint@16.3.1_typescript@5.4.5_vite@4.5.2/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_VfkuCOhudb from "/vercel/path0/src/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.12.7_eslint@8.57.0_sass@1.75.0_stylelint@16.3.1_typescript@5.4.5_vite@4.5.2/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_zuJyh8k92j from "/vercel/path0/src/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.12.7_eslint@8.57.0_sass@1.75.0_stylelint@16.3.1_typescript@5.4.5_vite@4.5.2/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_1UFbeRmyGd from "/vercel/path0/src/node_modules/.pnpm/nuxt-site-config@1.6.7_@nuxt+devtools@1.0.6_@vue+compiler-core@3.4.21_fuse.js@7.0.0_nuxt@3.8._7ksfjb5u632coe3shfndzf5pbu/node_modules/nuxt-site-config/dist/runtime/plugins/0.siteConfig.mjs";
import payload_client_BEeuL2vVRc from "/vercel/path0/src/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.12.7_eslint@8.57.0_sass@1.75.0_stylelint@16.3.1_typescript@5.4.5_vite@4.5.2/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_8hSy9ckXuw from "/vercel/path0/src/node_modules/.pnpm/@pinia+nuxt@0.5.1_typescript@5.4.5_vue@3.4.21/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/vercel/path0/src/.nuxt/components.plugin.mjs";
import prefetch_client_KNzNaIN6pw from "/vercel/path0/src/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.12.7_eslint@8.57.0_sass@1.75.0_stylelint@16.3.1_typescript@5.4.5_vite@4.5.2/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_B71c4qmE7Y from "/vercel/path0/src/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.12.7_eslint@8.57.0_sass@1.75.0_stylelint@16.3.1_typescript@5.4.5_vite@4.5.2/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_dgo1tQB3tH from "/vercel/path0/src/node_modules/.pnpm/nuxt@3.8.2_@types+node@20.12.7_eslint@8.57.0_sass@1.75.0_stylelint@16.3.1_typescript@5.4.5_vite@4.5.2/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import vercel_X9S0nty7R0 from "/vercel/path0/src/plugins/vercel.ts";
import vuetify_7h9QAQEssH from "/vercel/path0/src/plugins/vuetify.ts";
export default [
  revive_payload_client_I6BKwuD2zX,
  unhead_VfkuCOhudb,
  router_zuJyh8k92j,
  _0_siteConfig_1UFbeRmyGd,
  payload_client_BEeuL2vVRc,
  plugin_vue3_8hSy9ckXuw,
  components_plugin_KR1HBZs4kY,
  prefetch_client_KNzNaIN6pw,
  chunk_reload_client_B71c4qmE7Y,
  check_outdated_build_client_dgo1tQB3tH,
  vercel_X9S0nty7R0,
  vuetify_7h9QAQEssH
]