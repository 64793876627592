import { default as about_45contentsCsBkcWlzcxMeta } from "/vercel/path0/src/pages/about-contents.vue?macro=true";
import { default as about_45site2QfNoYP0LXMeta } from "/vercel/path0/src/pages/about-site.vue?macro=true";
import { default as contactkkOuvHuhQaMeta } from "/vercel/path0/src/pages/contact.vue?macro=true";
import { default as environmentOhZz9XPIZLMeta } from "/vercel/path0/src/pages/environment.vue?macro=true";
import { default as guideewXWxOlQNVMeta } from "/vercel/path0/src/pages/guide.vue?macro=true";
import { default as indexkP7wmsbr11Meta } from "/vercel/path0/src/pages/index.vue?macro=true";
import { default as indexB2BEcrpTV8Meta } from "/vercel/path0/src/pages/learn/cat-subsidy/[localGovernmentCode]/index.vue?macro=true";
import { default as how_45toz2tDTdiZSRMeta } from "/vercel/path0/src/pages/learn/cat-subsidy/how-to.vue?macro=true";
import { default as indexgjpoa6XrptMeta } from "/vercel/path0/src/pages/learn/cat-subsidy/index.vue?macro=true";
import { default as product_45storyipRFSreYZdMeta } from "/vercel/path0/src/pages/learn/cat-subsidy/product-story.vue?macro=true";
import { default as indexDlRCLbdPqWMeta } from "/vercel/path0/src/pages/learn/index.vue?macro=true";
import { default as indexbfmEXcJJKvMeta } from "/vercel/path0/src/pages/learn/lost-cat-contact/index.vue?macro=true";
import { default as how_45tojKT1AVI75fMeta } from "/vercel/path0/src/pages/learn/lost-cat/how-to.vue?macro=true";
import { default as indexPBHehkx3pXMeta } from "/vercel/path0/src/pages/learn/lost-cat/index.vue?macro=true";
import { default as indexmNis4ho1zdMeta } from "/vercel/path0/src/pages/learn/ubiquitous/index.vue?macro=true";
import { default as about_45catW8F20GKBYbMeta } from "/vercel/path0/src/pages/learn/ubiquitous/posts/about-cat.vue?macro=true";
import { default as about_45municipalityAQW9ZQK3NFMeta } from "/vercel/path0/src/pages/learn/ubiquitous/posts/about-municipality.vue?macro=true";
import { default as linkMkZ9gIHQGNMeta } from "/vercel/path0/src/pages/link.vue?macro=true";
import { default as how_45toSExneBJQGxMeta } from "/vercel/path0/src/pages/seek/furusatonouzei/how-to.vue?macro=true";
import { default as indexaMYbjKwW1KMeta } from "/vercel/path0/src/pages/seek/furusatonouzei/index.vue?macro=true";
import { default as indexOmI3dmfchBMeta } from "/vercel/path0/src/pages/seek/index.vue?macro=true";
import { default as whats_45newnX7qfagKDiMeta } from "/vercel/path0/src/pages/whats-new.vue?macro=true";
export default [
  {
    name: about_45contentsCsBkcWlzcxMeta?.name ?? "about-contents",
    path: about_45contentsCsBkcWlzcxMeta?.path ?? "/about-contents",
    meta: about_45contentsCsBkcWlzcxMeta || {},
    alias: about_45contentsCsBkcWlzcxMeta?.alias || [],
    redirect: about_45contentsCsBkcWlzcxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/about-contents.vue").then(m => m.default || m)
  },
  {
    name: about_45site2QfNoYP0LXMeta?.name ?? "about-site",
    path: about_45site2QfNoYP0LXMeta?.path ?? "/about-site",
    meta: about_45site2QfNoYP0LXMeta || {},
    alias: about_45site2QfNoYP0LXMeta?.alias || [],
    redirect: about_45site2QfNoYP0LXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/about-site.vue").then(m => m.default || m)
  },
  {
    name: contactkkOuvHuhQaMeta?.name ?? "contact",
    path: contactkkOuvHuhQaMeta?.path ?? "/contact",
    meta: contactkkOuvHuhQaMeta || {},
    alias: contactkkOuvHuhQaMeta?.alias || [],
    redirect: contactkkOuvHuhQaMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: environmentOhZz9XPIZLMeta?.name ?? "environment",
    path: environmentOhZz9XPIZLMeta?.path ?? "/environment",
    meta: environmentOhZz9XPIZLMeta || {},
    alias: environmentOhZz9XPIZLMeta?.alias || [],
    redirect: environmentOhZz9XPIZLMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/environment.vue").then(m => m.default || m)
  },
  {
    name: guideewXWxOlQNVMeta?.name ?? "guide",
    path: guideewXWxOlQNVMeta?.path ?? "/guide",
    meta: guideewXWxOlQNVMeta || {},
    alias: guideewXWxOlQNVMeta?.alias || [],
    redirect: guideewXWxOlQNVMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/guide.vue").then(m => m.default || m)
  },
  {
    name: indexkP7wmsbr11Meta?.name ?? "index",
    path: indexkP7wmsbr11Meta?.path ?? "/",
    meta: indexkP7wmsbr11Meta || {},
    alias: indexkP7wmsbr11Meta?.alias || [],
    redirect: indexkP7wmsbr11Meta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexB2BEcrpTV8Meta?.name ?? "learn-cat-subsidy-localGovernmentCode",
    path: indexB2BEcrpTV8Meta?.path ?? "/learn/cat-subsidy/:localGovernmentCode()",
    meta: indexB2BEcrpTV8Meta || {},
    alias: indexB2BEcrpTV8Meta?.alias || [],
    redirect: indexB2BEcrpTV8Meta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/learn/cat-subsidy/[localGovernmentCode]/index.vue").then(m => m.default || m)
  },
  {
    name: how_45toz2tDTdiZSRMeta?.name ?? "learn-cat-subsidy-how-to",
    path: how_45toz2tDTdiZSRMeta?.path ?? "/learn/cat-subsidy/how-to",
    meta: how_45toz2tDTdiZSRMeta || {},
    alias: how_45toz2tDTdiZSRMeta?.alias || [],
    redirect: how_45toz2tDTdiZSRMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/learn/cat-subsidy/how-to.vue").then(m => m.default || m)
  },
  {
    name: indexgjpoa6XrptMeta?.name ?? "learn-cat-subsidy",
    path: indexgjpoa6XrptMeta?.path ?? "/learn/cat-subsidy",
    meta: indexgjpoa6XrptMeta || {},
    alias: indexgjpoa6XrptMeta?.alias || [],
    redirect: indexgjpoa6XrptMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/learn/cat-subsidy/index.vue").then(m => m.default || m)
  },
  {
    name: product_45storyipRFSreYZdMeta?.name ?? "learn-cat-subsidy-product-story",
    path: product_45storyipRFSreYZdMeta?.path ?? "/learn/cat-subsidy/product-story",
    meta: product_45storyipRFSreYZdMeta || {},
    alias: product_45storyipRFSreYZdMeta?.alias || [],
    redirect: product_45storyipRFSreYZdMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/learn/cat-subsidy/product-story.vue").then(m => m.default || m)
  },
  {
    name: indexDlRCLbdPqWMeta?.name ?? "learn",
    path: indexDlRCLbdPqWMeta?.path ?? "/learn",
    meta: indexDlRCLbdPqWMeta || {},
    alias: indexDlRCLbdPqWMeta?.alias || [],
    redirect: indexDlRCLbdPqWMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/learn/index.vue").then(m => m.default || m)
  },
  {
    name: indexbfmEXcJJKvMeta?.name ?? "learn-lost-cat-contact",
    path: indexbfmEXcJJKvMeta?.path ?? "/learn/lost-cat-contact",
    meta: indexbfmEXcJJKvMeta || {},
    alias: indexbfmEXcJJKvMeta?.alias || [],
    redirect: indexbfmEXcJJKvMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/learn/lost-cat-contact/index.vue").then(m => m.default || m)
  },
  {
    name: how_45tojKT1AVI75fMeta?.name ?? "learn-lost-cat-how-to",
    path: how_45tojKT1AVI75fMeta?.path ?? "/learn/lost-cat/how-to",
    meta: how_45tojKT1AVI75fMeta || {},
    alias: how_45tojKT1AVI75fMeta?.alias || [],
    redirect: how_45tojKT1AVI75fMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/learn/lost-cat/how-to.vue").then(m => m.default || m)
  },
  {
    name: indexPBHehkx3pXMeta?.name ?? "learn-lost-cat",
    path: indexPBHehkx3pXMeta?.path ?? "/learn/lost-cat",
    meta: indexPBHehkx3pXMeta || {},
    alias: indexPBHehkx3pXMeta?.alias || [],
    redirect: indexPBHehkx3pXMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/learn/lost-cat/index.vue").then(m => m.default || m)
  },
  {
    name: indexmNis4ho1zdMeta?.name ?? "learn-ubiquitous",
    path: indexmNis4ho1zdMeta?.path ?? "/learn/ubiquitous",
    meta: indexmNis4ho1zdMeta || {},
    alias: indexmNis4ho1zdMeta?.alias || [],
    redirect: indexmNis4ho1zdMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/learn/ubiquitous/index.vue").then(m => m.default || m)
  },
  {
    name: about_45catW8F20GKBYbMeta?.name ?? "learn-ubiquitous-posts-about-cat",
    path: about_45catW8F20GKBYbMeta?.path ?? "/learn/ubiquitous/posts/about-cat",
    meta: about_45catW8F20GKBYbMeta || {},
    alias: about_45catW8F20GKBYbMeta?.alias || [],
    redirect: about_45catW8F20GKBYbMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/learn/ubiquitous/posts/about-cat.vue").then(m => m.default || m)
  },
  {
    name: about_45municipalityAQW9ZQK3NFMeta?.name ?? "learn-ubiquitous-posts-about-municipality",
    path: about_45municipalityAQW9ZQK3NFMeta?.path ?? "/learn/ubiquitous/posts/about-municipality",
    meta: about_45municipalityAQW9ZQK3NFMeta || {},
    alias: about_45municipalityAQW9ZQK3NFMeta?.alias || [],
    redirect: about_45municipalityAQW9ZQK3NFMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/learn/ubiquitous/posts/about-municipality.vue").then(m => m.default || m)
  },
  {
    name: linkMkZ9gIHQGNMeta?.name ?? "link",
    path: linkMkZ9gIHQGNMeta?.path ?? "/link",
    meta: linkMkZ9gIHQGNMeta || {},
    alias: linkMkZ9gIHQGNMeta?.alias || [],
    redirect: linkMkZ9gIHQGNMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/link.vue").then(m => m.default || m)
  },
  {
    name: how_45toSExneBJQGxMeta?.name ?? "seek-furusatonouzei-how-to",
    path: how_45toSExneBJQGxMeta?.path ?? "/seek/furusatonouzei/how-to",
    meta: how_45toSExneBJQGxMeta || {},
    alias: how_45toSExneBJQGxMeta?.alias || [],
    redirect: how_45toSExneBJQGxMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/seek/furusatonouzei/how-to.vue").then(m => m.default || m)
  },
  {
    name: indexaMYbjKwW1KMeta?.name ?? "seek-furusatonouzei",
    path: indexaMYbjKwW1KMeta?.path ?? "/seek/furusatonouzei",
    meta: indexaMYbjKwW1KMeta || {},
    alias: indexaMYbjKwW1KMeta?.alias || [],
    redirect: indexaMYbjKwW1KMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/seek/furusatonouzei/index.vue").then(m => m.default || m)
  },
  {
    name: indexOmI3dmfchBMeta?.name ?? "seek",
    path: indexOmI3dmfchBMeta?.path ?? "/seek",
    meta: indexOmI3dmfchBMeta || {},
    alias: indexOmI3dmfchBMeta?.alias || [],
    redirect: indexOmI3dmfchBMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/seek/index.vue").then(m => m.default || m)
  },
  {
    name: whats_45newnX7qfagKDiMeta?.name ?? "whats-new",
    path: whats_45newnX7qfagKDiMeta?.path ?? "/whats-new",
    meta: whats_45newnX7qfagKDiMeta || {},
    alias: whats_45newnX7qfagKDiMeta?.alias || [],
    redirect: whats_45newnX7qfagKDiMeta?.redirect || undefined,
    component: () => import("/vercel/path0/src/pages/whats-new.vue").then(m => m.default || m)
  }
]