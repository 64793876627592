/* eslint-disable import/order */
import { defineNuxtPlugin } from 'nuxt/app'

// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'
import type { DefaultsInstance } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import * as labs from 'vuetify/labs/components'
import { ja } from 'vuetify/locale'

export default defineNuxtPlugin((nuxtApp) => {
  const defaults: DefaultsInstance = {
    VDataTable: {
      fixedFooter: true,
      fixedHeader: true,
      hover: true,
      showCurrentPage: true,
    },
    VDataTableVirtual: {
      fixedHeader: true,
      hover: true,
    },
    VDataTableRows: {
      loading: true,
      hideNoData: true,
    },
  }
  const vuetify = createVuetify({
    components: {
      ...components,
      ...labs,
    },
    defaults,
    directives,
    locale: {
      locale: 'ja',
      messages: { ja },
    },
  })
  nuxtApp.vueApp.use(vuetify)
})
